import React, {useState} from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import axiosInstance from '../../utils/axiosInstance';
import {Helmet} from 'react-helmet-async';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';

import Unsplash from '../../components/Unsplash';
import objectToString from '../../utils/objectToString';
import {NotifyAlert} from '../../components/NotifyAlert';

import {useNavigate} from 'react-router-dom';

export default function ForgotPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [sucessMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setErrors('');
    const formData = new FormData(event.currentTarget);

    const user = {
      email: formData.get('email')
    };

    // Basic email and password validation
    let tempErrors = {};
    tempErrors.email = user.email ? '' : 'Email is required.';

    // If errors, return
    if (tempErrors.email) {
      setIsLoading(false);
      setErrors(tempErrors);
      setErrorMessage(
        'Field errors:\n' +
          Object.values(tempErrors)
            .filter((error) => error !== '')
            .join('\n')
      );
      return;
    }

    try {
      // Create the POST request
      await axiosInstance.post('/api/password-reset/', user, {
        headers: {'Content-Type': 'application/json'},
        withCredentials: false
      });

      setSuccessMessage('Email sent successfully. Please check your inbox.');
      setIsLoading(false);
    } catch (error) {
      console.error('Error reset password:', error);
      setErrorMessage(
        error.response?.data ? objectToString(error.response.data) : 'Failed to reset password'
      );
      setIsLoading(false);
      return;
    }

    // We can't use navigate('/feed') here, authkit needs a new request to backend after login
    // window.location.href = '/feed';
    setIsLoading(false);
  };

  const renderForm = (
    <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 1}}>
      <Stack spacing={3} mb={3}>
        <TextField
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          error={Boolean(errors.email)}
        />
      </Stack>

      <Stack direction="row" justifyContent="center">
        <LoadingButton size="small" type="submit" variant="contained" loading={isLoading}>
          Send Reset Email
        </LoadingButton>
      </Stack>
    </Box>
  );

  return (
    <>
      <Helmet>
        <title> Forgot Password | MePilots </title>
      </Helmet>
      {errorMessage != '' && (
        <NotifyAlert
          isEnabled={true}
          isError={true}
          message={errorMessage}
          closeCallback={() => {
            setErrorMessage('');
          }}
        />
      )}
      {sucessMessage != '' && (
        <NotifyAlert
          isEnabled={true}
          isError={false}
          message={sucessMessage}
          closeCallback={() => {
            setSuccessMessage('');
            navigate('/feed');
          }}
        />
      )}
      <Grid container component="main" sx={{height: '100vh'}}>
        <Unsplash />
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
          sx={{
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        >
          <Box
            sx={{
              // my: 4,
              // mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '100vh'
            }}
          >
            <Stack alignItems="center" justifyContent="center" sx={{height: 1}}>
              <Card
                sx={{
                  p: 5,
                  width: 1,
                  minWidth: 420
                }}
              >
                <Typography variant="h4" mb={2}>
                  Reset Password
                </Typography>

                {renderForm}
              </Card>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
