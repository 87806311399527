export default function objectToString(obj, isValueOnly = true) {
  return Object.entries(obj)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return isValueOnly ? value.join('\n') : `${key}: ${value.join('\n')}`;
      }
      return isValueOnly ? value : `${key}: ${value}`;
    })
    .join('\n');
}
