import {useEffect} from 'react';
import axiosInstance from '../../utils/axiosInstance';
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';

export const Logout = () => {
  const signOut = useSignOut();
  const authUser = useAuthUser();
  const refreshToken = authUser.refreshToken;
  const authHeader = useAuthHeader();

  useEffect(() => {
    (async () => {
      try {
        await axiosInstance.post(
          '/api/auth/logout/',
          {
            refresh: refreshToken
          },
          {headers: {'Content-Type': 'application/json', Authorization: authHeader}},
          {withCredentials: false}
        );
      } catch (e) {
        console.log('logout not working', e);
      }
      signOut();
      // We can't use navigate('/login') here, authkit needs a new request to backend after logout
      window.location.href = '/login';
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div></div>;
};

export default Logout;
