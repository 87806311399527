import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import {useTheme} from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import {Link as RouterLink} from 'react-router-dom';

import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import Iconify from '../../components/iconify';
import {hasRole} from '../../utils/hasRole';

export default function RenderInfo({
  public_available,
  blogPostId,
  ContentId,
  handleCopyToClipboard
}) {
  const theme = useTheme();
  const iconColor = theme.palette.mode === 'dark' ? 'white' : 'black';

  const authUser = useAuthUser();
  const isAuthenticated = useIsAuthenticated();

  return (
    <Stack
      direction="row"
      // flexWrap="wrap"
      spacing={1.5}
      justifyContent="flex-end"
      sx={{
        // mt: 3,
        color: 'text.disabled',
        display: 'flex'
      }}
    >
      {isAuthenticated && hasRole(authUser, 'admin') && (
        <Stack
          direction="row"
          sx={{
            opacity: 0.48,
            color: 'common.white'
          }}
        >
          <Tooltip title={'Edit post'}>
            <Link
              component={RouterLink}
              to={`/editor/${blogPostId}`}
              variant="contained"
              underline="none"
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: 'inherit', // Inherit color from parent
                textDecoration: 'none' // Remove text decoration
              }}
            >
              <Iconify
                width={16}
                icon={public_available ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                sx={{mr: 0.5, color: iconColor}}
              />
            </Link>
          </Tooltip>
        </Stack>
      )}
      <Stack
        direction="row"
        sx={{
          opacity: 0.48,
          color: 'common.white'
        }}
      >
        {ContentId !== undefined && (
          <Tooltip title={'Copy link to clipboard'}>
            <Link
              variant="contained"
              component="button"
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: 'inherit', // Inherit color from parent
                textDecoration: 'none' // Remove text decoration
              }}
              onClick={() => handleCopyToClipboard(ContentId)}
            >
              <Iconify width={16} icon={'eva:share-fill'} sx={{mr: 0.5, color: iconColor}} />
            </Link>
          </Tooltip>
        )}
      </Stack>
    </Stack>
  );
}
