import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import {useTheme} from '@mui/material/styles';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import {Link} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';
import Logo from '../logo';
import {ColorModeContext} from '../../theme';

const pages = ['Feed', 'Newsletter', 'Bookmarks', 'FAQ'];
const settings = ['Profile', 'Logout'];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleClickMenu = (event) => {
    if (event.target.value === undefined) {
      return;
    }

    const selectedValue = event.target.value.toLowerCase();
    navigate('/' + selectedValue);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component={Link}
            to="/"
            sx={{
              mr: 2,
              display: {xs: 'none', md: 'flex'},
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none'
            }}
          >
            {/* <Logo disabledLink sx={{ display: { xs: 'none', md: 'flex' }, mr: 2 }} /> MePilots */}
            <Logo disabledLink logoFull />
          </Typography>

          <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: {xs: 'block', md: 'none'}
              }}
            >
              {isAuthenticated ? (
                pages.map((page) => (
                  <MenuItem
                    key={page}
                    component={Link}
                    to={page.toLocaleLowerCase()}
                    onClick={handleCloseNavMenu}
                  >
                    <Typography textAlign="center" color="primary">
                      {page}
                    </Typography>
                  </MenuItem>
                ))
              ) : (
                <>
                  <MenuItem key="feed" component={Link} to={'/feed'} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center" color="primary">
                      Feed
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    key="newsletter"
                    component={Link}
                    to={'/newsletter'}
                    onClick={handleCloseNavMenu}
                  >
                    <Typography textAlign="center" color="primary">
                      Newsletter
                    </Typography>
                  </MenuItem>
                  <MenuItem key="login" component={Link} to={'/login'} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center" color="primary">
                      Login
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    key="signup"
                    component={Link}
                    to={'/signup'}
                    onClick={handleCloseNavMenu}
                  >
                    <Typography textAlign="center" color="primary">
                      Sign Up
                    </Typography>
                  </MenuItem>
                  <MenuItem key="signup" component={Link} to={'/faq'} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center" color="primary">
                      FAQ
                    </Typography>
                  </MenuItem>
                </>
              )}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component={Link}
            to="/"
            sx={{
              mr: 2,
              display: {xs: 'flex', md: 'none'},
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none'
            }}
          >
            {/* <Logo disabledLink sx={{ display: { xs: 'none', md: 'flex' }, mr: 2 }} /> MePilots */}
            <Logo disabledLink logoFull />
          </Typography>
          <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
            {!isAuthenticated && (
              <>
                <Button
                  key="feed"
                  value="feed"
                  onClick={handleClickMenu}
                  sx={{my: 2, color: 'white', display: 'block'}}
                >
                  Feed
                </Button>
                <Button
                  key="newsletter"
                  value="newsletter"
                  onClick={handleClickMenu}
                  sx={{my: 2, color: 'white', display: 'block'}}
                >
                  Newsletter
                </Button>
                <Button
                  key="login"
                  value="login"
                  onClick={handleClickMenu}
                  sx={{my: 2, color: 'white', display: 'block'}}
                >
                  Login
                </Button>
                <Button
                  key="signup"
                  value="signup"
                  onClick={handleClickMenu}
                  sx={{my: 2, color: 'white', display: 'block'}}
                >
                  Sign Up
                </Button>
                <Button
                  key="faq"
                  value="faq"
                  onClick={handleClickMenu}
                  sx={{my: 2, color: 'white', display: 'block'}}
                >
                  FAQ
                </Button>
              </>
            )}
            {pages.map(
              (page) =>
                isAuthenticated && (
                  <Button
                    key={page}
                    value={page}
                    onClick={handleClickMenu}
                    sx={{my: 2, color: 'white', display: 'block'}}
                  >
                    {page}
                  </Button>
                )
            )}
          </Box>
          <Box sx={{flexGrow: 0, display: {xs: 'none', md: 'flex'}}}>{theme.palette.mode} mode</Box>
          <Box sx={{flexGrow: 0}}>
            <Tooltip title="Change mode">
              <IconButton sx={{ml: 1}} onClick={colorMode.toggleColorMode} color="inherit">
                {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
              </IconButton>
            </Tooltip>
          </Box>
          <Box sx={{flexGrow: 0}}>
            <Tooltip title="Open settings">
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenUserMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{mt: '45px'}}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {isAuthenticated ? (
                settings.map((setting) => (
                  <MenuItem
                    key={setting}
                    component={Link}
                    to={setting.toLocaleLowerCase()}
                    onClick={handleCloseUserMenu}
                  >
                    <Typography textAlign="center" color="primary">
                      {setting}
                    </Typography>
                  </MenuItem>
                ))
              ) : (
                // If user is not authenticated, show login and signup options at profile icon
                <>
                  <MenuItem
                    key="login"
                    component={Link}
                    to={'/login'}
                    onClick={handleCloseUserMenu}
                  >
                    <Typography textAlign="center" color="primary">
                      Login
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    key="signup"
                    component={Link}
                    to={'/signup'}
                    onClick={handleCloseUserMenu}
                  >
                    <Typography textAlign="center" color="primary">
                      Sign Up
                    </Typography>
                  </MenuItem>
                </>
              )}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
