import React, {useState, useEffect} from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import IconButton from '@mui/material/IconButton';

const ScrollToTop = ({showBelow}) => {
  const [show, setShow] = useState(showBelow ? false : true);

  const handleScroll = () => {
    if (window.scrollY > showBelow) {
      if (!show) setShow(true);
    } else {
      if (show) setShow(false);
    }
  };

  const handleClick = () => {
    window[`scrollTo`]({top: 0, behavior: `smooth`});
  };

  useEffect(() => {
    if (showBelow) {
      window.addEventListener(`scroll`, handleScroll);
      return () => window.removeEventListener(`scroll`, handleScroll);
    }
  });

  return (
    <div>
      {show && (
        <IconButton
          onClick={handleClick}
          aria-label="to top"
          component="span"
          style={{
            zIndex: 2,
            position: 'fixed',
            bottom: '2vh',
            backgroundColor: '#DCDCDC',
            color: 'black',
            '&:hover, &.MuiFocusVisible': {
              transition: '0.3s',
              color: '#397BA6',
              backgroundColor: '#DCDCDC'
            },
            right: '5%'
          }}
        >
          <ExpandLessIcon />
        </IconButton>
      )}
    </div>
  );
};
export default ScrollToTop;
