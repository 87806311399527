/* eslint-disable no-unused-vars */
import {useMemo, useEffect, createContext, useState} from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import {createTheme, ThemeProvider as MUIThemeProvider} from '@mui/material/styles';

import {palette} from './palette';
import {shadows} from './shadows';
import {overrides} from './overrides';
import {typography} from './typography';
import {customShadows} from './custom-shadows';

// ----------------------------------------------------------------------

export const ColorModeContext = createContext({toggleColorMode: () => {}});

export default function ThemeToggleMode({children}) {
  const [mode, setMode] = useState('light');
  // const colorMode = useMemo(
  //   () => ({
  //     toggleColorMode: () => {
  //       setMode((prevmode) => (prevmode === 'light' ? 'dark' : 'light'));
  //       localStorage.setItem('colorMode', mode);
  //     }
  //   }),
  //   []
  // );
  const colorMode = {
    toggleColorMode: () => {
      const newMode = mode === 'light' ? 'dark' : 'light';
      setMode(newMode);
      localStorage.setItem('colorMode', newMode);
    }
  };

  const theme = useMemo(
    () =>
      createTheme({
        // palette: palette(mode),
        palette: {
          mode
        },
        // components: {
        //   MuiCssBaseline: {
        //     styleOverrides: (themeParam) => `
        //       body {
        //         background: ${themeParam.palette.mode === 'light' ? themeParam.palette.grey[50] : themeParam.palette.grey[1000]};
        //       }
        //     `
        //   }
        // },
        typography,
        shadows: shadows(),
        customShadows: customShadows(),
        shape: {borderRadius: 8}
      }),
    [mode]
  );

  useEffect(() => {
    const existingPreference = localStorage.getItem('colorMode');
    if (existingPreference) {
      existingPreference === 'light' ? setMode('light') : setMode('dark');
    } else {
      setMode('light');
      localStorage.setItem('colorMode', 'light');
    }
  }, []);

  theme.components = overrides(theme);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <MUIThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        {children}
      </MUIThemeProvider>
    </ColorModeContext.Provider>
  );
}
