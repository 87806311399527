import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import AppBar from '../../components/AppBar';
import {Link} from 'react-router-dom';

// ----------------------------------------------------------------------

export default function NotFoundView() {
  return (
    <>
      <AppBar />
      <Container>
        <Box
          sx={{
            py: 12,
            maxWidth: 480,
            mx: 'auto',
            display: 'flex',
            minHeight: '100vh',
            textAlign: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <Typography variant="h3" sx={{mb: 3}}>
            Sorry, page not found!
          </Typography>

          <Typography sx={{color: 'text.secondary'}}>
            Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be
            sure to check your spelling.
          </Typography>

          <Box
            component="img"
            // src="/assets/illustrations/illustration_404.svg"
            src="/logo/logo.png"
            sx={{
              mx: 'left',
              height: 260,
              my: {xs: 5, sm: 10}
            }}
          />

          <Button component={Link} to="/" size="large" variant="contained">
            Go to Home
          </Button>
        </Box>
      </Container>
    </>
  );
}
