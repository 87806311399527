import {useState} from 'react';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import {Helmet} from 'react-helmet-async';
import Paper from '@mui/material/Paper';
import {useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Switch from '@mui/material/Switch';
import LoadingButton from '@mui/lab/LoadingButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Iconify from '../../components/iconify';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';

import axiosInstance from '../../utils/axiosInstance';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import {NotifyAlert} from '../../components/NotifyAlert';
import objectToString from '../../utils/objectToString';
import updateProfile from '../../api/updateProfile';
import useEditAuthState from '../../utils/useEditAuthState';
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';

import {useDialogs} from '@toolpad/core/useDialogs';
import Dialog from '@mui/material/Dialog';
import Alert from '@mui/material/Alert';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

// ----------------------------------------------------------------------

function MyCustomDialog({open, onClose, payload}) {
  return (
    <Dialog fullWidth open={open} onClose={() => onClose()}>
      <DialogTitle>Custom Error Handler</DialogTitle>
      <DialogContent>
        <Alert severity="error">
          {`An error occurred while deleting item "${payload.id}":`}
          <pre>{payload.error}</pre>
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Close me</Button>
      </DialogActions>
    </Dialog>
  );
}

export default function ProfileView() {
  const theme = useTheme();
  const authUser = useAuthUser();

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [sucessMessage, setSuccessMessage] = useState('');
  const [firstName, setFirstName] = useState(authUser.first_name);
  const [lastName, setLastName] = useState(authUser.last_name);
  const [newsletterChecked, setNewsletterChecked] = useState(authUser.profile.receive_newsletter);

  const editUserState = useEditAuthState();
  const dialogs = useDialogs();
  const [isDeleting, setIsDeleting] = useState(false);

  const signOut = useSignOut();
  const authHeader = useAuthHeader();

  const handleProfileSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true);
    setErrors('');
    const formData = new FormData(event.currentTarget);

    const user = {
      first_name: formData.get('first_name'),
      last_name: formData.get('last_name')
    };

    // Basic email and password validation
    let tempErrors = {};
    tempErrors.first_name = user.first_name ? '' : 'First name is required.';
    tempErrors.last_name = user.last_name ? '' : 'Last name is required.';

    // If errors, return
    if (tempErrors.first_name || tempErrors.last_name) {
      setIsLoading(false);
      setErrors(tempErrors);
      setErrorMessage(
        'Field errors:\n' +
          Object.values(tempErrors)
            .filter((error) => error !== '')
            .join('\n')
      );
      return;
    }

    try {
      await axiosInstance.patch('/api/auth/user/', user, {
        headers: {'Content-Type': 'application/json', Authorization: authHeader},
        withCredentials: false
      });
    } catch (error) {
      console.error('Error update profile:', error);
      setErrorMessage(
        error.response?.data ? objectToString(error.response.data) : 'Failed to update profile.'
      );
      setIsLoading(false);
      return;
    }

    const newUserProfile = Object.assign({}, authUser, {
      first_name: user.first_name,
      last_name: user.last_name
    });
    editUserState(newUserProfile);

    setErrorMessage('');
    setSuccessMessage('Profile updated.');
    setIsLoading(false);
  };

  const handlePasswordSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true);
    setErrors('');
    const formData = new FormData(event.currentTarget);

    const user = {
      new_password1: formData.get('password'),
      new_password2: formData.get('password-confirm')
    };

    // Basic password validation
    let tempErrors = {};
    tempErrors.password =
      user.new_password1 === user.new_password2 ? '' : 'Password did not match.';
    tempErrors.password = !user.new_password1 ? 'Password is required.' : tempErrors.password;

    // If errors, return
    if (tempErrors.password) {
      setIsLoading(false);
      setErrors(tempErrors);
      setErrorMessage(
        'Field errors:\n' +
          Object.values(tempErrors)
            .filter((error) => error !== '')
            .join('\n')
      );
      return;
    }

    try {
      // Create the POST request
      await axiosInstance.post('/api/auth/password/change/', user, {
        headers: {'Content-Type': 'application/json', Authorization: authHeader},
        withCredentials: false
      });
    } catch (error) {
      console.error('Error change password:', error);
      setErrorMessage(
        error.response?.data ? objectToString(error.response.data) : 'Failed to change password.'
      );
      setIsLoading(false);
      return;
    }

    setErrorMessage('');
    setSuccessMessage('Password change successfully!');
    setIsLoading(false);
  };

  const handleDeactivateUser = async () => {
    const id = await dialogs.prompt(
      `This action cannot be undone!\n\nType your username (${authUser.username}) to confirm you understand:`,
      {
        okText: 'Delete',
        cancelText: 'Cancel'
      }
    );

    if (id && id != authUser.username) {
      await dialogs.alert('Username does not match. Please try again.');
      return;
    }

    if (id) {
      const deleteConfirmed = await dialogs.confirm(`Are you sure you want to delete "${id}"?`);
      if (deleteConfirmed) {
        try {
          setIsDeleting(true);
          await axiosInstance.delete('/api/auth/deactivate/', {
            headers: {'Content-Type': 'application/json', Authorization: authHeader},
            withCredentials: false
          });
          await dialogs.alert('Deleted!');
          signOut();
          // We can't use navigate('/login') here, authkit needs a new request to backend after logout
          window.location.href = '/login';
        } catch (error) {
          const message = error.response?.data
            ? objectToString(error.response.data)
            : 'Error deleting current user.';
          await dialogs.open(MyCustomDialog, {id, error: message});
        } finally {
          setIsDeleting(false);
        }
      }
    }
  };

  const handleNewsletterChange = async (event) => {
    const newUserProfile = Object.assign({}, authUser, {
      profile: {...authUser.profile, receive_newsletter: event.target.checked}
    });
    if (await updateProfile(newUserProfile)) {
      setSuccessMessage('Newsletter preference updated.');
      setNewsletterChecked(newUserProfile.profile.receive_newsletter);
      editUserState(newUserProfile);
    } else {
      setErrorMessage('Failed to update newsletter preference.');
    }
  };

  const card = (
    <>
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            mb: 2
          }}
        >
          <Avatar
            alt="profile pict"
            src="/assets/images/avatars/default_profile_400x400.png"
            sx={{width: 200, height: 200}}
          />
        </Box>

        <Typography
          gutterBottom
          sx={{color: 'text.secondary', fontSize: 14, textAlign: 'center', mb: 2}}
        >
          {authUser.username}
        </Typography>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="column" alignItems="left" justifyContent="center">
            <Typography variant="h7" component="div">
              Newsletter
            </Typography>
            <Typography sx={{color: 'text.secondary', mb: 1.5}}>Receive email</Typography>
          </Stack>
          <Switch checked={newsletterChecked} onChange={handleNewsletterChange} label="Label" />
        </Stack>
      </CardContent>
      <CardActions sx={{justifyContent: 'center'}}>
        <LoadingButton
          size="small"
          variant="contained"
          loading={isDeleting}
          onClick={handleDeactivateUser}
        >
          Delete
        </LoadingButton>
      </CardActions>
    </>
  );

  const renderProfileForm = (
    <Box component="form" noValidate onSubmit={handleProfileSubmit} sx={{mt: 1}}>
      <Stack spacing={3}>
        <Stack direction="row" spacing={3}>
          <TextField
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            value={authUser.username}
            disabled={true}
          />

          <TextField
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={authUser.email}
            disabled={true}
          />
        </Stack>

        <Stack direction="row" spacing={3}>
          <TextField
            fullWidth
            id="first_name"
            label="First Name"
            name="first_name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            error={Boolean(errors.first_name)}
          />

          <TextField
            fullWidth
            id="last_name"
            label="Last Name"
            name="last_name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            error={Boolean(errors.last_name)}
          />
        </Stack>
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{my: 3}}>
        <LoadingButton
          size="medium"
          type="submit"
          variant="contained"
          // color="inherit"
          loading={isLoading}
        >
          Save Changes
        </LoadingButton>
      </Stack>
    </Box>
  );

  const renderPasswordForm = (
    <Box component="form" noValidate onSubmit={handlePasswordSubmit} sx={{mt: 3}}>
      <Stack spacing={3}>
        <Stack direction="row" spacing={3}>
          <TextField
            fullWidth
            id="password"
            autoComplete="current-password"
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            error={Boolean(errors.password)}
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                )
              }
            }}
          />

          <TextField
            fullWidth
            id="password-confirm"
            autoComplete="current-password"
            name="password-confirm"
            label="Confirm pass"
            type={showPassword ? 'text' : 'password'}
            error={Boolean(errors.password)}
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                )
              }
            }}
          />
        </Stack>
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{my: 3}}>
        <LoadingButton
          size="medium"
          type="submit"
          variant="contained"
          // color="inherit"
          loading={isLoading}
        >
          Change Password
        </LoadingButton>
      </Stack>
    </Box>
  );

  return (
    <>
      <Helmet>
        <title> Profile | MePilots </title>
      </Helmet>
      {errorMessage != '' && (
        <NotifyAlert
          isEnabled={true}
          isError={true}
          message={errorMessage}
          closeCallback={() => {
            setErrorMessage('');
          }}
        />
      )}
      {sucessMessage != '' && (
        <NotifyAlert
          isEnabled={true}
          isError={false}
          message={sucessMessage}
          closeCallback={() => {
            setSuccessMessage('');
          }}
        />
      )}
      <Container alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} mt={2}>
          <Typography variant="h4">Profile</Typography>
        </Stack>
        <Grid
          container
          spacing={2}
          columns={12}
          direction="row"
          sx={{
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Grid item size={{xs: 12, md: 4}}>
            <Paper className={theme.palette.mode} elevation={3} sx={{padding: 2}}>
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="space-between"
                mb={5}
                mt={2}
              >
                <Typography variant="h6" mb={2}>
                  Preferences
                </Typography>
                <Box sx={{minWidth: 275}}>
                  <Card variant="outlined">{card}</Card>
                </Box>
              </Stack>
            </Paper>
          </Grid>
          <Grid item size={{xs: 12, md: 8}}>
            <Stack alignItems="center" justifyContent="center" sx={{height: 1}}>
              <Card
                sx={{
                  p: 5,
                  width: 1,
                  minWidth: 275
                }}
              >
                <Typography variant="h6" sx={{mt: 2, mb: 5}}>
                  Account Info
                </Typography>

                {renderProfileForm}

                <Divider mb={2} />

                {renderPasswordForm}
              </Card>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
