import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import axiosInstance from '../../utils/axiosInstance';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const Unsplash = () => {
  const [unsplash, setUnsplash] = useState({});

  useEffect(() => {
    const defaultUnsplash = {
      url: 'https://images.unsplash.com/photo-1547920759-8f8be2d2742c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w1ODIzOTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE3MTExNjE4NzB8&ixlib=rb-4.0.3&q=80&w=1080',
      username: 'nicholaspryde',
      profile: 'https://unsplash.com/@nicholaspryde'
    };

    async function fetchData() {
      try {
        const {data} = await axiosInstance.get('/unsplash');

        const unsplashData = {
          url: data.urls.regular,
          username: data.user.username,
          profile: data.user.links.html
        };

        setUnsplash(unsplashData);
      } catch (error) {
        console.error('Error fetching unsplash data:', error);
        setUnsplash(defaultUnsplash);
      }
    }

    // This force show something in development
    if (process.env.NODE_ENV !== 'production') {
      setUnsplash(defaultUnsplash);
    } else {
      fetchData();
    }
  }, []);

  return (
    <Grid
      item
      xs={false}
      sm={4}
      md={7}
      sx={{
        backgroundImage: `url(${unsplash.url})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: (t) =>
          t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative'
      }}
    >
      {unsplash === 'undefined' && (
        <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          padding: '1rem',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          color: '#fff',
          textAlign: 'center',
          display: {xs: 'none', sm: 'block'}
        }}
      >
        <Typography variant="body1">
          Photo by{' '}
          <Link
            href={unsplash.profile + '?utm_source=MePilots&utm_medium=referral'}
            color="inherit"
            target="_blank"
          >
            {unsplash.username}
          </Link>{' '}
          on{' '}
          <Link
            href="https://unsplash.com/?utm_source=MePilots&utm_medium=referral"
            color="inherit"
            target="_blank"
          >
            Unsplash
          </Link>
        </Typography>
      </Box>
    </Grid>
  );
};

export default Unsplash;
