import React from 'react';
import AppBar from '../AppBar';
import {Outlet, useNavigation} from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

export default function BaseLayout() {
  const navigation = useNavigation();
  return (
    <>
      <AppBar />
      <Outlet />
      {navigation.state === 'loading' && (
        <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </>
  );
}
