import React, {useState} from 'react';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import axiosInstance from '../../utils/axiosInstance';
import {Helmet} from 'react-helmet-async';
import {Link as RRDLink} from 'react-router-dom';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import {alpha, useTheme} from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Iconify from '../../components/iconify';
import {LoginSocialFacebook, LoginSocialTwitter, LoginSocialGoogle} from 'reactjs-social-login';

import Unsplash from '../../components/Unsplash';
import objectToString from '../../utils/objectToString';
import {NotifyAlert} from '../../components/NotifyAlert';

export default function SignInSide() {
  const theme = useTheme();

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const signIn = useSignIn();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setErrors('');
    const formData = new FormData(event.currentTarget);

    const user = {
      email: formData.get('email'),
      password: formData.get('password')
    };

    // Basic email and password validation
    let tempErrors = {};
    tempErrors.email = user.email ? '' : 'Email is required.';
    tempErrors.password = user.password ? '' : 'Password is required.';

    // If errors, return
    if (tempErrors.email || tempErrors.password) {
      setIsLoading(false);
      setErrors(tempErrors);
      setErrorMessage(
        'Field errors:\n' +
          Object.values(tempErrors)
            .filter((error) => error !== '')
            .join('\n')
      );
      return;
    }

    try {
      // Create the POST request
      const {data} = await axiosInstance.post(
        '/api/auth/login/',
        user,
        {
          headers: {'Content-Type': 'application/json'}
        },
        {withCredentials: false}
      );

      const success = signIn({
        auth: {
          token: data.access,
          type: 'Bearer'
        },
        refresh: data.refresh,
        userState: {
          ...data.user,
          refreshToken: data.refresh
        }
      });

      if (!success) {
        setErrorMessage('Failed to signin.');
        setIsLoading(false);
        return;
      }
    } catch (error) {
      console.error('Error signin:', error);
      setErrorMessage(
        error.response?.data ? objectToString(error.response.data) : 'Failed to signin.'
      );
      setIsLoading(false);
      return;
    }

    // We can't use navigate('/feed') here, authkit needs a new request to backend after login
    window.location.href = '/feed';
    setIsLoading(false);
  };

  const handleFacebook = async (facebookData) => {
    console.log(facebookData);
    setIsLoading(true);

    const user = {
      access_token: facebookData.data.accessToken
    };

    try {
      const {data} = await axiosInstance.post(
        '/api/auth/facebook/',
        user,
        {
          headers: {'Content-Type': 'application/json'}
        },
        {withCredentials: false}
      );

      const success = signIn({
        auth: {
          token: data.access,
          type: 'Bearer'
        },
        refresh: data.refresh,
        userState: {
          ...data.user,
          refreshToken: data.refresh
        }
      });

      if (!success) {
        setErrorMessage('Failed to signin.');
        setIsLoading(false);
        return;
      }
    } catch (error) {
      console.error('Error signin:', error);
      setErrorMessage(
        error.response?.data ? objectToString(error.response.data) : 'Failed to signin.'
      );
      setIsLoading(false);
      return;
    }

    window.location.href = '/feed';
    setIsLoading(false);
  };

  const handleTwitter = async (twitterData) => {
    console.log(twitterData);
    setIsLoading(true);

    const tokenData = {
      access_token: twitterData.data.access_token
    };

    try {
      const {data} = await axiosInstance.post(
        '/api/auth/twitter/',
        tokenData,
        {
          headers: {'Content-Type': 'application/json'}
        },
        {withCredentials: false}
      );

      const success = signIn({
        auth: {
          token: data.access,
          type: 'Bearer'
        },
        refresh: data.refresh,
        userState: {
          ...data.user,
          refreshToken: data.refresh
        }
      });

      if (!success) {
        setErrorMessage('Failed to signin.');
        setIsLoading(false);
        return;
      }
    } catch (error) {
      console.error('Error signin:', error);
      setErrorMessage(
        error.response?.data ? objectToString(error.response.data) : 'Failed to signin.'
      );
      setIsLoading(false);
      return;
    }

    window.location.href = '/feed';
    setIsLoading(false);
  };

  const handleGoogle = async (googleData) => {
    console.log(googleData);
    setIsLoading(true);

    const tokenData = {
      code: googleData.data.code
    };

    try {
      const {data} = await axiosInstance.post(
        '/api/auth/google/',
        tokenData,
        {
          headers: {'Content-Type': 'application/json'}
        },
        {withCredentials: false}
      );

      const success = signIn({
        auth: {
          token: data.access,
          type: 'Bearer'
        },
        refresh: data.refresh,
        userState: {
          ...data.user,
          refreshToken: data.refresh
        }
      });

      if (!success) {
        setErrorMessage('Failed to signin.');
        setIsLoading(false);
        return;
      }
    } catch (error) {
      console.error('Error signin:', error);
      setErrorMessage(
        error.response?.data ? objectToString(error.response.data) : 'Failed to signin.'
      );
      setIsLoading(false);
      return;
    }

    window.location.href = '/feed';
    setIsLoading(false);
  };

  const renderForm = (
    <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 1}}>
      <Stack spacing={3}>
        <TextField
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          error={Boolean(errors.email)}
        />

        <TextField
          required
          fullWidth
          id="password"
          autoComplete="current-password"
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          error={Boolean(errors.password)}
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              )
            }
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{my: 3}}>
        <Link component={RRDLink} to="/forgot-password" variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        // color="inherit"
        loading={isLoading}
      >
        Login
      </LoadingButton>
    </Box>
  );

  return (
    <>
      <Helmet>
        <title> Login | MePilots </title>
      </Helmet>
      {errorMessage != '' && (
        <NotifyAlert
          isEnabled={true}
          isError={true}
          message={errorMessage}
          closeCallback={() => {
            setErrorMessage('');
          }}
        />
      )}
      <Grid container component="main" sx={{height: '100vh'}}>
        <Unsplash />
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
          sx={{
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        >
          <Box
            sx={{
              // my: 4,
              // mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '100vh'
            }}
          >
            <Stack alignItems="center" justifyContent="center" sx={{height: 1}}>
              <Card
                sx={{
                  p: 5,
                  width: 1,
                  maxWidth: 420
                }}
              >
                <Typography variant="h4">Sign in to MePilots</Typography>

                <Typography variant="body2" sx={{mt: 2, mb: 5}}>
                  Don’t have an account?
                  <Link component={RRDLink} to="/signup" variant="subtitle2" sx={{ml: 0.5}}>
                    Get started
                  </Link>
                </Typography>

                <Stack direction="row" justifyContent="center" spacing={5}>
                  <LoginSocialGoogle
                    access_type="offline"
                    client_id={window._env_.REACT_APP_GG_APP_ID}
                    onLoginStart={() => {
                      console.log('login start');
                    }}
                    onResolve={handleGoogle}
                    onReject={(err) => {
                      console.log(err);
                    }}
                  >
                    <Button
                      fullWidth
                      size="large"
                      color="inherit"
                      variant="outlined"
                      sx={{borderColor: alpha(theme.palette.grey[500], 0.16)}}
                    >
                      <Iconify icon="eva:google-fill" color="#DF3E30" />
                    </Button>
                  </LoginSocialGoogle>

                  <LoginSocialFacebook
                    isOnlyGetToken
                    appId={window._env_.REACT_APP_FB_APP_ID}
                    onLoginStart={() => {
                      console.log('login start');
                    }}
                    onResolve={handleFacebook}
                    onReject={(err) => {
                      console.log(err);
                    }}
                  >
                    <Button
                      fullWidth
                      size="large"
                      color="inherit"
                      variant="outlined"
                      sx={{borderColor: alpha(theme.palette.grey[500], 0.16)}}
                    >
                      <Iconify icon="eva:facebook-fill" color="#1877F2" />
                    </Button>
                  </LoginSocialFacebook>

                  <LoginSocialTwitter
                    isOnlyGetToken
                    client_id={window._env_.REACT_APP_TWITTER_V2_APP_KEY}
                    redirect_uri={window._env_.REACT_APP_TWITTER_REDIRECT_URI}
                    state="_twitter"
                    onLoginStart={() => {
                      console.log('login start');
                    }}
                    onResolve={handleTwitter}
                    onReject={(err) => {
                      console.log(err);
                    }}
                  >
                    <Button
                      fullWidth
                      size="large"
                      color="inherit"
                      variant="outlined"
                      sx={{borderColor: alpha(theme.palette.grey[500], 0.16)}}
                    >
                      <Iconify icon="eva:twitter-fill" color="#1C9CEA" />
                    </Button>
                  </LoginSocialTwitter>
                </Stack>

                <Divider sx={{my: 3}}>
                  <Typography variant="body2" sx={{color: 'text.secondary'}}>
                    OR
                  </Typography>
                </Divider>

                {renderForm}
              </Card>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
