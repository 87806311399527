import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import FlightIcon from '@mui/icons-material/Flight';
import {useTheme} from '@mui/material/styles';
import {Helmet} from 'react-helmet-async';
import {Link as RouterLink} from 'react-router-dom';

function Copyright() {
  return (
    <>
      <Typography variant="body2" color="text.secondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://mepilots.com/">
          MePilots
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
      <Typography variant="body2" color="text.secondary" align="center">
        <Link component={RouterLink} to="/privacy-policy" color="inherit">
          Privacy Policy
        </Link>
        {'\u00A0\u00A0\u00A0\u00A0'}
        <Link component={RouterLink} to="/terms-service" color="inherit">
          Terms of Service
        </Link>
        {'\u00A0\u00A0\u00A0\u00A0'}
        <Link component={RouterLink} to="/cookies-policy" color="inherit">
          Cookies Policy
        </Link>
        {'\u00A0\u00A0\u00A0\u00A0'}
        <Link component={RouterLink} to="/use-policy" color="inherit">
          Use Policy
        </Link>
      </Typography>
    </>
  );
}

export default function LandingPage() {
  const theme = useTheme();
  return (
    <>
      <Helmet>
        <title> MePilots </title>
      </Helmet>
      <Container
        maxWidth="lg"
        align="center"
        sx={{minHeight: '90vh', display: 'flex', flexDirection: 'column'}}
      >
        <Box
          component="img"
          src="/logo/logo_background.png"
          sx={{
            mx: 'auto',
            height: 260,
            my: {xs: 5, sm: 10},
            display: {xs: 'none', md: 'flex'}
          }}
        />
        <Box
          component="img"
          src="/logo/logo.png"
          sx={{
            mx: 'auto',
            height: 260,
            my: {xs: 5, sm: 10},
            display: {xs: 'flex', md: 'none'}
          }}
        />
        <Box sx={{my: 4, flex: 1}}>
          <Typography variant="h4" component="h1" sx={{mb: 2}}>
            Your Daily Dose of Aviation News!
          </Typography>
          <Button sx={{mt: 3}} component={RouterLink} to="/feed" size="large" variant="contained">
            Go to Feed
          </Button>
          <Typography sx={{mt: 6, mb: 3, color: 'text.secondary'}}>
            <FlightIcon
              color={theme.palette.mode === 'dark' ? 'primary' : 'default'}
              fontSize="large"
              sx={{mr: 1, verticalAlign: 'middle'}}
            />
            {'MePilots: Made with love by aviation lovers for aviation lovers!'}
          </Typography>
        </Box>
        <Box sx={{mt: 'auto'}}>
          <Copyright />
        </Box>
      </Container>
    </>
  );
}
