import AuthProvider from 'react-auth-kit';
import createStore from 'react-auth-kit/createStore';
import AuthOutlet from '@auth-kit/react-router/AuthOutlet';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import React from 'react';
import {refresh} from './utils/refreshToken';
import {HelmetProvider} from 'react-helmet-async';
import {DialogsProvider} from '@toolpad/core/useDialogs';

import Logout from './components/Logout';
import LandingPage from './pages/LandingPage';
import {Editor} from './pages/Editor';
import SignInSide from './pages/SignInSide';
import SignUp from './pages/SignUp';
import {NewPassword, ForgotPassword} from './pages/ForgotPassword';
import {VerifyEmail} from './components/VerifyEmail';
import BaseLayout from './components/BaseLayout';
import {FeedView} from './pages/Feed/view';
import {RootBoundary} from './pages/error';
import ThemeToggleMode from './theme';
import {TanstackProvider} from './api';
import {LoginSocialTwitter} from 'reactjs-social-login';
import Plausible from 'plausible-tracker';
import ProfileView from './pages/Profile/profile-view';
import {PrivacyPolicy, TermsService, CookiesPolicy, UsePolicy} from './pages/Policies';
import {Newsletter} from './pages/Newsletter';

import {NotifyAlert} from './components/NotifyAlert';
import {getBlogPost, getContent} from './api';
import {FAQ} from './pages/FAQ';

const {enableAutoPageviews} = Plausible({
  apiHost: window.location.origin
});
enableAutoPageviews();

const store = createStore({
  authName: '_auth',
  authType: 'localstorage',
  refresh: refresh,
  debug: false
});

const router = createBrowserRouter([
  {
    path: '/',
    element: <BaseLayout />,
    errorElement: <RootBoundary />,
    children: [
      {
        path: '',
        element: <LandingPage />
      },
      {
        path: 'login',
        Component: SignInSide
      },
      {
        path: 'signup',
        Component: SignUp
      },
      {
        path: 'account-confirm-email/:key/:optional?',
        Component: VerifyEmail
      },
      {
        path: 'twitter',
        Component: LoginSocialTwitter
      },
      {
        path: 'privacy-policy',
        Component: PrivacyPolicy
      },
      {
        path: 'terms-service',
        Component: TermsService
      },
      {
        path: 'cookies-policy',
        Component: CookiesPolicy
      },
      {
        path: 'use-policy',
        Component: UsePolicy
      },
      {
        path: 'feed',
        Component: FeedView
      },
      {
        path: 'feed/:postId',
        Component: FeedView,
        loader: async ({params}) => {
          return await getContent(params.postId);
        },
        errorElement: <NotifyAlert isEnabled={true} isError={true} message="Failed to load post." />
      },
      {
        path: 'forgot-password',
        Component: ForgotPassword
      },
      {
        path: 'password-reset-confirm/:uid/:token/',
        Component: NewPassword
      },
      {
        path: 'accountspassword/*', // this is used in the email template when Account Already Exists on signup
        Component: ForgotPassword
      },
      {
        path: 'newsletter',
        Component: Newsletter
      },
      {
        path: 'faq',
        Component: FAQ
      },
      {
        element: <AuthOutlet fallbackPath="login" />,
        children: [
          {
            path: 'logout',
            Component: Logout
          },
          {
            path: 'bookmarks',
            Component: FeedView
          },
          {
            path: 'profile',
            Component: ProfileView
          },
          {
            path: 'editor/:blogPostID',
            Component: Editor,
            loader: async ({params}) => {
              return await getBlogPost(params.blogPostID);
            },
            errorElement: (
              <NotifyAlert isEnabled={true} isError={true} message="Failed to load a blog post." />
            )
          },
          {
            path: 'editor/new',
            Component: Editor
          }
        ]
      }
    ]
  }
]);

function App() {
  return (
    <ThemeToggleMode>
      <HelmetProvider>
        <TanstackProvider>
          <DialogsProvider>
            <AuthProvider store={store}>
              <RouterProvider router={router} />
            </AuthProvider>
          </DialogsProvider>
        </TanstackProvider>
      </HelmetProvider>
    </ThemeToggleMode>
  );
}

export default App;
