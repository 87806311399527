import {useContext} from 'react';
import AuthContext from 'react-auth-kit/AuthContext';
import {AuthError} from 'react-auth-kit/errors';
import {isAuthenticated} from 'react-auth-kit/utils/utils';
import useSignIn from 'react-auth-kit/hooks/useSignIn';

export default function useEditAuthState() {
  const authContext = useContext(AuthContext);
  const signIn = useSignIn();

  if (authContext === null) {
    throw new AuthError('Auth Provider is missing. Please add the AuthProvider before Router');
  }

  return (newState) => {
    if (isAuthenticated(authContext.authValue)) {
      return signIn({
        auth: {
          token: authContext.authValue.auth.token,
          type: authContext.authValue.auth.type
        },
        refresh: authContext.authValue.refresh.token,
        userState: newState
      });
    } else {
      return null;
    }
  };
}
