import React, {useState} from 'react';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import axiosInstance from '../../utils/axiosInstance';
import {Helmet} from 'react-helmet-async';
import {Link as RRDLink} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Iconify from '../../components/iconify';
import Unsplash from '../../components/Unsplash';
import {NotifyAlert} from '../../components/NotifyAlert';
import objectToString from '../../utils/objectToString';

export default function SignUp() {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [sucessMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true);
    setErrors('');
    const formData = new FormData(event.currentTarget);

    const user = {
      email: formData.get('email'),
      username: formData.get('username'),
      password1: formData.get('password'),
      password2: formData.get('password-confirm')
    };

    // Basic email and password validation
    let tempErrors = {};
    tempErrors.email = user.email ? '' : 'Email is required.';
    tempErrors.username = user.username ? '' : 'Username is required.';
    tempErrors.password = user.password1 === user.password2 ? '' : 'Password did not match.';
    tempErrors.password = !user.password1 ? 'Password is required.' : tempErrors.password;

    // If errors, return
    if (tempErrors.email || tempErrors.username || tempErrors.password) {
      setIsLoading(false);
      setErrors(tempErrors);
      setErrorMessage(
        'Field errors:\n' +
          Object.values(tempErrors)
            .filter((error) => error !== '')
            .join('\n')
      );
      return;
    }

    try {
      // Create the POST request
      const {data} = await axiosInstance.post(
        '/api/auth/registration/',
        user,
        {
          headers: {'Content-Type': 'application/json'}
        },
        {withCredentials: false}
      );

      console.log(data);
    } catch (error) {
      console.error('Error signup:', error);
      setErrorMessage(
        error.response?.data ? objectToString(error.response.data) : 'Failed to signup.'
      );
      setIsLoading(false);
      return;
    }

    setErrorMessage('');
    setSuccessMessage('Signup successful!\nPlease check your email to verify your account.');
    setIsLoading(false);
  };

  const renderForm = (
    <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 1}}>
      <Stack spacing={3}>
        <TextField
          required
          fullWidth
          id="username"
          label="Username"
          name="username"
          autoComplete="username"
          autoFocus
          error={Boolean(errors.username)}
        />

        <TextField
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          error={Boolean(errors.email)}
        />

        <Stack direction="row" spacing={3}>
          <TextField
            required
            fullWidth
            id="password"
            autoComplete="current-password"
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            error={Boolean(errors.password)}
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                )
              }
            }}
          />

          <TextField
            required
            fullWidth
            id="password-confirm"
            autoComplete="current-password"
            name="password-confirm"
            label="Confirm pass"
            type={showPassword ? 'text' : 'password'}
            error={Boolean(errors.password)}
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                )
              }
            }}
          />
        </Stack>
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{my: 3}}>
        {/* <RRDLink component={RRDLink} to="/signup">
          TODO - RECAPTCH HERE
        </RRDLink> */}
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        // color="inherit"
        loading={isLoading}
      >
        Sign up
      </LoadingButton>
    </Box>
  );

  return (
    <>
      <Helmet>
        <title> Signup | MePilots </title>
      </Helmet>
      {errorMessage != '' && (
        <NotifyAlert
          isEnabled={true}
          isError={true}
          message={errorMessage}
          closeCallback={() => {
            setErrorMessage('');
          }}
        />
      )}
      {sucessMessage != '' && (
        <NotifyAlert
          isEnabled={true}
          isError={false}
          message={sucessMessage}
          closeCallback={() => {
            navigate('/login');
          }}
        />
      )}
      <Grid container component="main" sx={{height: '100vh'}}>
        <Unsplash />
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
          sx={{
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        >
          <Box
            sx={{
              // my: 4,
              // mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '100vh'
            }}
          >
            <Stack alignItems="center" justifyContent="center" sx={{height: 1}}>
              <Card
                sx={{
                  p: 5,
                  width: 1,
                  maxWidth: 420
                }}
              >
                <Typography variant="h4">Sign up to MePilots</Typography>

                <Typography variant="body2" sx={{mt: 2, mb: 5}}>
                  Have already an account?
                  <Link component={RRDLink} to="/login" variant="subtitle2" sx={{ml: 0.5}}>
                    Sign in
                  </Link>
                </Typography>

                {renderForm}

                <Typography
                  variant="body2"
                  color="text.secondary"
                  align="center"
                  sx={{mt: 2, mb: 5}}
                >
                  {'By signing up, I agree to '}
                  <Link component={RRDLink} to="/terms-service" color="inherit">
                    Terms of Service
                  </Link>
                  {' and '}
                  <Link component={RRDLink} to="/privacy-policy" color="inherit">
                    Privacy Policy.
                  </Link>
                  {'.'}
                </Typography>
              </Card>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
