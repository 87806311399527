import {useState, useMemo, useCallback} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, {autocompleteClasses} from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

import Iconify from '../../components/iconify';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

// ----------------------------------------------------------------------
export default function PostSearch({
  posts,
  onSearch,
  searchContentObject,
  setSearchContentObject,
  isLoading
}) {
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const debounce = useCallback(
    (func, delay) => {
      let debounceTimer;
      return function (...args) {
        isOpen ? setLoading(true) : setLoading(false);
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(this, args), delay);
      };
    },
    [isOpen]
  );

  const handleInputChange = useMemo(
    () =>
      debounce((newInputValue) => {
        setLoading(false);
        // If the fake search object is selected, don't trigger the search
        if (newInputValue.startsWith('Search')) return;
        if (searchContentObject?.title === newInputValue) return;
        onSearch(newInputValue);
      }, 2000),
    [debounce, searchContentObject, onSearch]
  );

  return (
    <Autocomplete
      sx={{width: 280}}
      autoHighlight
      selectOnFocus={false}
      popupIcon={null}
      slotProps={{
        paper: {
          sx: {
            width: 320,
            [`& .${autocompleteClasses.option}`]: {
              typography: 'body2'
            }
          }
        }
      }}
      loading={loading || isLoading}
      value={searchContentObject}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          setSearchContentObject({
            title: newValue
          });
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setSearchContentObject({
            title: newValue.inputValue
          });
        } else {
          setSearchContentObject(newValue);
        }
        console.log('Selected search object:', newValue);
      }}
      onClose={() => {
        setIsOpen(false);
        setLoading(false);
      }}
      onOpen={() => {
        setIsOpen(true);
      }}
      filterOptions={(options, params) => {
        const {inputValue} = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.title);
        if (inputValue !== '' && !isExisting) {
          options.unshift({
            inputValue,
            title: `Search "${inputValue}"`
          });
        }
        return options;
      }}
      options={posts}
      getOptionLabel={(post) => (post?.id ? `${post.title} : Id "${post.id}"` : post.title)}
      isOptionEqualToValue={(option, value) =>
        option.id === value.id && option.title === value.title
      }
      onInputChange={(event, newInputValue) => {
        handleInputChange(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Search post..."
          slotProps={{
            input: {
              ...params.InputProps,
              startAdornment: (
                <>
                  <Iconify
                    icon="eva:search-fill"
                    sx={{ml: 1, width: 20, height: 20, color: 'text.disabled'}}
                  />
                  {params.InputProps.startAdornment}
                </>
              ),
              endAdornment: (
                <>
                  {loading || isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }
          }}
        />
      )}
      renderOption={(props, option, {inputValue}) => {
        const {key, ...optionProps} = props;
        const matches = match(option.title, inputValue, {insideWords: true});
        const parts = parse(option.title, matches);

        return (
          <li key={key} {...optionProps}>
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400
                  }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          </li>
        );
      }}
    />
  );
}
