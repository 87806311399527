import {useState, useEffect} from 'react';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import {Helmet} from 'react-helmet-async';
import {useInfiniteQuery, keepPreviousData, useQuery, useQueryClient} from '@tanstack/react-query';
import {getContents, getContentsSearch, getBlogPost, bookmarkContent} from '../../../api';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {useTheme} from '@mui/material/styles';
import {useNavigate, useLocation, useLoaderData} from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import {RichTextReadOnly} from 'mui-tiptap';
import Plausible from 'plausible-tracker';

import ScrollToTop from '../../../components/ScrollToTop';
import PostCard from '../post-card';
import PostSort from '../post-sort';
import PostSearch from '../post-search';
import InfiniteScroll from 'react-infinite-scroll-component';

import {NotifyAlert} from '../../../components/NotifyAlert';

import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';
import {useWindowWidth} from '@react-hook/window-size';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';
// import {Tweet} from 'react-tweet';
import useExtensions from '../../Editor/useExtensions';
import objectToString from '../../../utils/objectToString';

const {trackEvent} = Plausible({
  apiHost: window.location.origin
});

// ----------------------------------------------------------------------

export default function FeedView() {
  const [open, setOpen] = useState(false);
  const [currentPost, setCurrentPost] = useState(null);
  const [blogPostHtml, setBlogPostHtml] = useState('');

  const windowWidth = useWindowWidth();
  const [width, setWidth] = useState('60%');

  const theme = useTheme();
  const [contentLoading, setContentLoading] = useState(false);
  const [filterContentType, setFilterContentType] = useState('all');
  const [filterContentLanguage, setFilterContentLanguage] = useState('international');
  const [searchContent, setSearchContent] = useState('');
  const [searchContentObject, setSearchContentObject] = useState(null);
  const [isBookmark, setIsBookmark] = useState(false);

  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const location = useLocation();
  const postLoaded = useLoaderData();

  const extensions = useExtensions({
    placeholder: 'Add your own content here...'
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [sucessMessage, setSuccessMessage] = useState('');
  const queryClient = useQueryClient();

  useEffect(() => {
    if (postLoaded) {
      handleChange(postLoaded);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.pathname.startsWith('/bookmarks')) {
      setIsBookmark(true);
    } else {
      setIsBookmark(false);
    }
  }, [location]);

  useEffect(() => {
    if (windowWidth < 600) {
      setWidth('90%');
    } else {
      setWidth('60%');
    }
  }, [windowWidth]);

  useEffect(() => {
    if (currentPost?.content_type === 'X' && open) {
      setContentLoading(true);
      // eslint-disable-next-line no-undef
      twttr.widgets
        .createTweet(currentPost.platform_id, document.getElementById('post-here'), {
          align: 'left',
          theme: theme.palette.mode === 'light' ? 'light' : 'dark'
        })
        .then(function () {
          setContentLoading(false);
          console.log('Tweet displayed.');
        });
    }

    if (currentPost?.content_type === 'MP' && open) {
      setContentLoading(true);
      (async () => {
        try {
          const data = await getBlogPost(currentPost.platform_id);
          setBlogPostHtml(data.html_content);
          setContentLoading(false);
        } catch (error) {
          console.error('Error loading post:', error);
          setErrorMessage(
            error.response?.data
              ? objectToString(error.response.data)
              : 'Failed to load a blog post.'
          );
          handleClose();
          return;
        }
      })();
      setContentLoading(false);
    }

    const button = document.querySelector('.lty-playbtn');

    if (!button) return;

    function createObserver() {
      let observer;

      let options = {
        rootMargin: '-50%',
        threshold: 1
      };

      observer = new IntersectionObserver(() => button.click(), options);
      observer.observe(button);
    }
    return createObserver();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClose = (e) => {
    setOpen(false);
    setCurrentPost(null);
    e.stopPropagation();
  };

  const handleChange = (post) => {
    setCurrentPost(post);
    setOpen(true);

    trackEvent('StartPlayer', {
      callback: () => console.log('StartPlayer sent'),
      props: {
        content_type: post.content_type,
        content_id: post.id,
        plataform_id: post.platform_id
      }
    });
  };

  const handleFilterContentType = (data) => {
    console.log('handleFilterContentType', data.target);
    setFilterContentType(data.target.value);
  };

  const handleFilterContentLanguage = (data) => {
    console.log('handleFilterContentLanguage', data.target);
    setFilterContentLanguage(data.target.value);
  };

  const handleSearchContent = (newInputValue) => {
    console.log('handleSearchContent', newInputValue);
    setSearchContent(newInputValue);
  };

  const handleBookmark = async (id, isDelete) => {
    // Force user to login before bookmarking
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }

    const result = await bookmarkContent(id, isDelete ? true : false);
    if (result) {
      console.log('Bookmark success');
      setSuccessMessage(isDelete ? 'Bookmark removed' : 'Bookmark added');
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('contents') //&& query.queryKey[1]?.isBookmark === true
      });
    } else {
      console.log('Bookmark failed');
      setErrorMessage(isDelete ? 'Failed to remove bookmark' : 'Failed to add bookmark');
    }
  };

  const handleCopyToClipboard = (id) => {
    const postLink = `${window.location.protocol}//${window.location.host}/feed/${id}`;
    navigator.clipboard.writeText(postLink);
    setSuccessMessage('Link copied to clipboard');
  };

  const {
    data: queryData,
    error: queryError,
    fetchNextPage,
    hasNextPage,
    status
  } = useInfiniteQuery({
    queryKey: [
      'contents',
      {filterContentType, filterContentLanguage, searchContentObject, isBookmark}
    ],
    queryFn: getContents,
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      return lastPage.next ? lastPage.prevPage + 1 : undefined;
    }
  });

  let posts = queryData?.pages.flatMap((page) => page.results) || [];

  // eslint-disable-next-line no-unused-vars
  const {
    isPending,
    isError: isSearchError,
    error: errorSearch,
    data: dataSearch,
    isFetching,
    isPlaceholderData
  } = useQuery({
    queryKey: ['search', searchContent, isBookmark],
    queryFn: () => getContentsSearch(searchContent, isBookmark),
    placeholderData: keepPreviousData
  });

  const postsSearch = dataSearch?.results || [];

  const renderFilter = () => {
    return (
      <>
        <PostSearch
          posts={postsSearch}
          onSearch={handleSearchContent}
          searchContentObject={searchContentObject}
          setSearchContentObject={setSearchContentObject}
          isLoading={isPending || isFetching || isPlaceholderData}
        />

        {searchContentObject?.id === undefined && (
          <Stack mb={5} direction="row" alignItems="baseline" spacing={3}>
            <PostSort
              options={[
                {value: 'all', label: 'All'},
                {value: 'MP', label: 'MePilots'},
                {value: 'X', label: 'X'},
                {value: 'YT', label: 'YouTube'}
              ]}
              onSort={handleFilterContentType}
              selectedValue={filterContentType}
            />
            <PostSort
              options={[
                {value: 'international', label: 'International'},
                {value: 'EN', label: 'English'},
                {value: 'PT', label: 'Portuguese'}
              ]}
              onSort={handleFilterContentLanguage}
              selectedValue={filterContentLanguage}
            />
          </Stack>
        )}
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title> {isBookmark ? 'Bookmarks' : 'Feed'} | MePilots </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} mt={2}>
          <Typography variant="h4">{isBookmark ? 'Bookmarks' : 'Feed'}</Typography>
        </Stack>
        {status === 'pending' && (
          <>
            <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={true}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </>
        )}
        {/* Errors from query */}
        {(status === 'error' || isSearchError) && (
          <NotifyAlert
            isEnabled={true}
            isError={true}
            message={isSearchError ? errorSearch.message : queryError.message || queryError}
          />
        )}
        {/* Errors from loading post */}
        {errorMessage != '' && (
          <NotifyAlert
            isEnabled={true}
            isError={true}
            message={errorMessage}
            closeCallback={() => {
              setErrorMessage('');
            }}
          />
        )}
        {sucessMessage != '' && (
          <NotifyAlert
            isEnabled={true}
            isError={false}
            message={sucessMessage}
            closeCallback={() => {
              setSuccessMessage('');
            }}
          />
        )}
        {queryData && (
          <>
            <Stack
              mb={5}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{display: {xs: 'none', md: 'flex'}}}
            >
              {renderFilter()}
            </Stack>
            <Stack
              mb={5}
              direction="column"
              alignItems="baseline"
              spacing={3}
              sx={{display: {xs: 'flex', md: 'none'}}}
            >
              {renderFilter()}
            </Stack>
            <InfiniteScroll
              dataLength={posts ? posts.length : 0}
              next={() => fetchNextPage()}
              style={{overflow: 'hidden', width: '100%', height: '100%'}}
              hasMore={hasNextPage}
              loader={
                <Stack mt={5} direction="column" alignItems="center" justifyContent="space-between">
                  <CircularProgress color="inherit" />
                </Stack>
              }
            >
              <Grid container spacing={3}>
                {posts.map((post, index) => (
                  <PostCard
                    key={post.id}
                    post={post}
                    index={index}
                    postSelect={handleChange}
                    handleBookmark={handleBookmark}
                    handleCopyToClipboard={handleCopyToClipboard}
                  />
                ))}
              </Grid>
            </InfiniteScroll>
          </>
        )}
        {open && (
          <Backdrop
            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={open}
            onClick={handleClose}
          >
            <Stack
              sx={{width: '100%'}}
              direction="column"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid
                sx={{
                  width: width,
                  padding: 2,
                  display: currentPost?.content_type === 'X' ? 'flex' : 'inline',
                  zIndex: (theme) => theme.zIndex.drawer + 2,
                  maxHeight: '90vh', // Limit the height to 90% of the viewport height
                  overflowY: 'auto' // Enable vertical scrollbar if content exceeds the height
                }}
                id="post-here"
                alignItems="center"
                justifyContent="center"
                onClick={(e) => e.stopPropagation()}
              >
                {currentPost?.content_type === 'YT' && (
                  <LiteYouTubeEmbed
                    key={currentPost?.platform_id}
                    id={currentPost?.platform_id}
                    title={currentPost?.title}
                  />
                )}
                {currentPost?.content_type === 'MP' && (
                  <Paper
                    className={theme.palette.mode}
                    elevation={3}
                    sx={{padding: 2, maxHeight: '600px', overflowY: 'auto'}}
                  >
                    <RichTextReadOnly content={blogPostHtml} extensions={extensions} />
                  </Paper>
                )}
                {/* {currentPost?.content_type === 'X' && (
                  <Paper
                    className={theme.palette.mode}
                    sx={{maxWidth: '100%', maxHeight: '90%', padding: 2}}
                    elevation={3}
                  >
                    {process.env.NODE_ENV !== 'production' ? (
                      <Tweet id={currentPost?.platform_id} />
                    ) : (
                      <Tweet
                        apiUrl={currentPost?.platform_id && `/tweet/${currentPost?.platform_id}`}
                      />
                    )}
                  </Paper>
                )} */}
              </Grid>
              {contentLoading && <CircularProgress color="inherit" />}
              <IconButton
                onClick={handleClose}
                component="span"
                style={{
                  zIndex: 3,
                  position: 'fixed',
                  bottom: '2vh',
                  backgroundColor: '#DCDCDC',
                  color: 'black',
                  '&:hover, &.MuiFocusVisible': {
                    transition: '0.3s',
                    color: '#397BA6',
                    backgroundColor: '#DCDCDC'
                  },
                  right: '5%'
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
          </Backdrop>
        )}
      </Container>
      <ScrollToTop showBelow={250} />
    </>
  );
}
