import axiosInstance from '../utils/axiosInstance';

export const getContents = async ({pageParam, queryKey}) => {
  const authToken = localStorage.getItem('_auth');

  // use authToken to make authenticated requests
  const headers = {'Content-Type': 'application/json'};
  if (authToken) {
    headers['Authorization'] = `Bearer ${authToken}`;
  }

  const [
    // eslint-disable-next-line no-unused-vars
    _key,
    {filterContentType, filterContentLanguage, searchContentObject, isBookmark = false}
  ] = queryKey;

  // console.log('getContents', pageParam);
  // console.log('filterContentType', filterContentType);

  // if a search result is selected, return the object
  if (searchContentObject?.id !== undefined) {
    return {results: [searchContentObject], prevPage: pageParam};
  }

  let apiUrl = `/api/contents/?page=${pageParam}`;

  if (filterContentType !== 'all') {
    apiUrl += `&content_type=${filterContentType}`;
  }

  if (filterContentLanguage !== 'international') {
    apiUrl += `&content_language=${filterContentLanguage}`;
  }

  if (isBookmark) {
    apiUrl += `&bookmark=${isBookmark}`;
  }

  // if this is a search query (fake object created by the search), add the search term to the URL
  if (searchContentObject !== null && searchContentObject.title !== '') {
    apiUrl += `&search=${searchContentObject.title}`;
  }

  const response = await axiosInstance.get(apiUrl, {
    headers: headers
  });

  return {...response.data, prevPage: pageParam};
};

export const getContentsSearch = async (searchContent = '', isBookmark = false) => {
  const authToken = localStorage.getItem('_auth');

  // use authToken to make authenticated requests
  const headers = {'Content-Type': 'application/json'};
  if (authToken) {
    headers['Authorization'] = `Bearer ${authToken}`;
  }

  // eslint-disable-next-line no-unused-vars
  // const [_key, {searchContent}] = queryKey;

  // console.log('getContents', pageParam);
  // console.log('filterContentType', filterContentType);

  let apiUrl = `/api/contents/?page=1`;

  if (searchContent !== '' && searchContent !== undefined) {
    apiUrl += `&search=${searchContent}`;
  }

  if (isBookmark) {
    apiUrl += `&bookmark=${isBookmark}`;
  }

  const response = await axiosInstance.get(apiUrl, {
    headers: headers
  });

  return {...response.data};
};

export const getContent = async (contentId) => {
  const authToken = localStorage.getItem('_auth');

  // use authToken to make authenticated requests
  const headers = {'Content-Type': 'application/json'};
  if (authToken) {
    headers['Authorization'] = `Bearer ${authToken}`;
  }

  const response = await axiosInstance.get(`/api/contents/${contentId}/`, {
    headers: headers
  });

  // return response.data;
  return {...response.data};
};
