import axiosInstance from '../utils/axiosInstance';
import objectToString from '../utils/objectToString';

async function updateProfile(userData, isDelete = false) {
  const authToken = localStorage.getItem('_auth');

  // Remove specific properties if they exist
  // eslint-disable-next-line no-unused-vars
  const {pk, username, email, ...filteredUserData} = userData;

  try {
    const method = isDelete ? 'delete' : 'patch';
    const url = `api/auth/user/`;
    await axiosInstance({
      method,
      url,
      data: filteredUserData,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      },
      withCredentials: false
    });

    return true;
  } catch (error) {
    const message = error.response?.data
      ? objectToString(error.response.data)
      : 'Failed to update user profile.';
    console.error('User profile API error:', message);
    return false;
  }
}

export default updateProfile;
