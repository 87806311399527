import {useState} from 'react';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import {useNavigate} from 'react-router-dom';
import Link from '@mui/material/Link';

import {Link as RouterLink} from 'react-router-dom';
import {useInfiniteQuery} from '@tanstack/react-query';
import {Helmet} from 'react-helmet-async';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';

import InfiniteScroll from 'react-infinite-scroll-component';
import Iconify from '../../components/iconify';
import {NotifyAlert} from '../../components/NotifyAlert';
import ScrollToTop from '../../components/ScrollToTop';
import {getBlogPosts} from '../../api/';
import {hasRole} from '../../utils/hasRole';

// import BlogCard from './blog-card';
import BlogPost from './blog-post';

export default function Newsletter() {
  const [sucessMessage, setSuccessMessage] = useState('');

  const authUser = useAuthUser();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  const {
    data: queryData,
    error: queryError,
    fetchNextPage,
    hasNextPage,
    status
  } = useInfiniteQuery({
    queryKey: ['blogPosts'],
    queryFn: getBlogPosts,
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      return lastPage.next ? lastPage.prevPage + 1 : undefined;
    }
  });

  let blogPosts = queryData?.pages.flatMap((page) => page.results) || [];

  const handleCopyToClipboard = (id) => {
    const postLink = `${window.location.protocol}//${window.location.host}/feed/${id}`;
    navigator.clipboard.writeText(postLink);
    setSuccessMessage('Link copied to clipboard');
  };

  const handlePostButton = () => {
    navigate('/editor/new');
  };

  return (
    <>
      <Helmet>
        <title> Newsletter | MePilots </title>
      </Helmet>
      <Container sx={{paddingBottom: 6}}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} mt={2}>
          <Typography variant="h4">Newsletter</Typography>
        </Stack>
        <Typography variant="body" color="text.secondary" gutterBottom>
          Stay up to date with the latest news and updates from MePilots. Log in and subscribe to
          our newsletter at your{' '}
          <Link component={RouterLink} to={`/profile`} variant="contained" underline="none">
            profile
          </Link>{' '}
          page to get the latest updates delivered straight to your inbox.
        </Typography>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} mt={2}>
          <Typography variant="h4" gutterBottom>
            Latest
          </Typography>
          {isAuthenticated && hasRole(authUser, 'admin') && (
            <Button
              variant="contained"
              color="inherit"
              onClick={handlePostButton}
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              New Post
            </Button>
          )}
        </Stack>
        {status === 'pending' && (
          <>
            <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={true}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </>
        )}
        {/* Errors from query */}
        {status === 'error' && (
          <NotifyAlert isEnabled={true} isError={true} message={queryError.message || queryError} />
        )}
        {sucessMessage != '' && (
          <NotifyAlert
            isEnabled={true}
            isError={false}
            message={sucessMessage}
            closeCallback={() => {
              setSuccessMessage('');
            }}
          />
        )}
        <InfiniteScroll
          dataLength={blogPosts ? blogPosts.length : 0}
          next={() => fetchNextPage()}
          style={{overflow: 'hidden', width: '100%', height: '100%'}}
          hasMore={hasNextPage}
          loader={
            <Stack mt={5} direction="column" alignItems="center" justifyContent="space-between">
              <CircularProgress color="inherit" />
            </Stack>
          }
        >
          <Grid item size={12}>
            {blogPosts.map((article, index) => (
              <BlogPost
                key={index}
                article={article}
                handleCopyToClipboard={handleCopyToClipboard}
              />
            ))}
          </Grid>
        </InfiniteScroll>
      </Container>
      <ScrollToTop showBelow={250} />
    </>
  );
}
