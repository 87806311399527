import axiosInstance from '../utils/axiosInstance';
import objectToString from '../utils/objectToString';

async function bookmarkContent(id, isDelete = false) {
  const authToken = localStorage.getItem('_auth');

  const postData = {
    content_id: id
  };

  try {
    const method = isDelete ? 'delete' : 'post';
    const url = `/api/bookmark/`;
    await axiosInstance({
      method,
      url,
      data: postData,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      },
      withCredentials: false
    });

    return true;
  } catch (error) {
    const message = error.response?.data
      ? objectToString(error.response.data)
      : 'Failed call bookmark API.';
    console.error('Bookmark API error:', message);
    return false;
  }
}

export default bookmarkContent;
