import createRefresh from 'react-auth-kit/createRefresh';
import axiosInstance from '../utils/axiosInstance';

export const refresh = createRefresh({
  interval: 10,
  refreshApiCallback: async (param) => {
    try {
      const response = await axiosInstance.post(
        '/api/auth/token/refresh/',
        {
          refresh: param.refreshToken
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${param.authToken}`
          }
        }
      );

      console.log('Refreshing');

      return {
        isSuccess: true,
        newAuthToken: response.data.access,
        newAuthTokenType: 'Bearer',
        newRefreshToken: response.data.refresh,
        newAuthUserState: {...param.authUserState, refreshToken: response.data.refresh}
      };
    } catch (error) {
      console.error(error);
      return {
        isSuccess: false
      };
    }
  }
});
