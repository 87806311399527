import {useState} from 'react';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {styled} from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

import {fDate} from '../../utils/format-time';
import {RichTextReadOnly} from 'mui-tiptap';
import useExtensions from '../Editor/useExtensions';
import RenderInfo from './render-info';

// ----------------------------------------------------------------------

const StyledTypography = styled(Typography)({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  overflow: 'hidden',
  textOverflow: 'ellipsis'
});

function Footer({article, authors, handleCopyToClipboard}) {
  const authorsModified = (Array.isArray(authors) ? authors : Array(authors)).map((author) => {
    return {
      name: author,
      avatar: '/logo/logo.png'
    };
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 2,
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px'
      }}
    >
      <Box sx={{display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center'}}>
        <AvatarGroup max={3}>
          {authorsModified.map((author, index) => (
            <Avatar
              key={index}
              alt={author.name}
              src={author.avatar}
              sx={{width: 24, height: 24}}
            />
          ))}
        </AvatarGroup>
        <Typography variant="caption">
          {authorsModified.map((author) => author.name).join(', ')}
        </Typography>
      </Box>
      <RenderInfo
        public_available={article.public_available}
        blogPostId={article.id}
        ContentId={article.content_id}
        handleCopyToClipboard={handleCopyToClipboard}
      />
      <Typography variant="caption">{fDate(article.published)}</Typography>{' '}
    </Box>
  );
}

const SyledCard = styled(Card)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: 0,
  height: '100%',
  backgroundColor: (theme.vars || theme).palette.background.paper,
  '&:hover': {
    backgroundColor: 'transparent',
    cursor: 'pointer'
  },
  '&:focus-visible': {
    outline: '3px solid',
    outlineColor: 'hsla(210, 98%, 48%, 0.5)',
    outlineOffset: '2px'
  }
}));

const SyledCardContent = styled(CardContent)({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  padding: 16,
  flexGrow: 1,
  '&:last-child': {
    paddingBottom: 16
  }
});

export default function BlogPost({article, index, handleCopyToClipboard}) {
  const [focusedCardIndex, setFocusedCardIndex] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleFocus = (index) => {
    setFocusedCardIndex(index);
  };

  const handleBlur = () => {
    setFocusedCardIndex(null);
  };

  const extensions = useExtensions({
    placeholder: ''
  });

  const blogTypeMap = {
    newsletter: 'Newsletter',
    news: 'News',
    flight_simulator: 'Flight Simulator',
    aviation: 'Aviation'
  };

  return (
    <>
      <Box
        key={index}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: 2,
          my: 2
        }}
      >
        <SyledCard
          variant="outlined"
          onFocus={() => handleFocus(3)}
          onBlur={handleBlur}
          tabIndex={0}
          className={focusedCardIndex === 3 ? 'Mui-focused' : ''}
          sx={{height: '100%'}}
        >
          <SyledCardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%'
            }}
          >
            <div>
              <Typography gutterBottom variant="caption" component="div">
                {blogTypeMap[article.blog_type]}
              </Typography>
              <Typography gutterBottom variant="h6" component="div">
                {article.title}
              </Typography>
              {isExpanded ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: 5
                  }}
                >
                  <RichTextReadOnly content={article.html_content} extensions={extensions} />
                </Box>
              ) : (
                <>
                  <StyledTypography variant="body2" color="text.secondary" gutterBottom>
                    {article.description}
                  </StyledTypography>
                  <Divider mb={2} />
                  {article.image_url !== '/logo/logo.png' && (
                    <Box
                      component="img"
                      alt={article.title}
                      src={article.image_url}
                      mt={2}
                      sx={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        top: 0,
                        width: 480,
                        aspectRatio: 16 / 9,
                        height: 'auto'
                      }}
                    />
                  )}
                </>
              )}
            </div>
            <Box sx={{'& > :not(style)': {m: 1}, display: 'flex', justifyContent: 'center'}}>
              <Fab
                variant="extended"
                onClick={() => {
                  setIsExpanded((prev) => !prev);
                }}
              >
                {isExpanded ? (
                  <>
                    <CloseFullscreenIcon sx={{mr: 1}} />
                    Collapse
                  </>
                ) : (
                  <>
                    <OpenInFullIcon sx={{mr: 1}} />
                    Expand
                  </>
                )}
              </Fab>
            </Box>
          </SyledCardContent>
          <Footer
            article={article}
            authors={article.author}
            handleCopyToClipboard={handleCopyToClipboard}
          />
        </SyledCard>
      </Box>
    </>
  );
}
