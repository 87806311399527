import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import axiosInstance from '../../utils/axiosInstance';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import {useNavigate} from 'react-router-dom';

export const VerifyEmail = () => {
  let {key} = useParams();
  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  // eslint-disable-next-line no-unused-vars
  const handleClose = (event, reason) => {
    // if (reason === 'clickaway') {
    //   return;
    // }

    setOpen(false);
    navigate('/login');
  };

  useEffect(() => {
    (async () => {
      try {
        const {data} = await axiosInstance.post(
          '/api/auth/registration/verify-email/',
          {
            key: key
          },
          {withCredentials: false}
        );

        console.log(data);
        setMessage('Email was verified succesfully!');
        setIsError(false);
      } catch (e) {
        console.log('verify email not working', e);
        setMessage('Error verifying email!');
        setIsError(true);
      }
      setOpen(true);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
      >
        <Alert
          onClose={handleClose}
          severity={isError ? 'error' : 'success'}
          variant="filled"
          sx={{width: '100%'}}
        >
          <p>{message}</p>
        </Alert>
      </Snackbar>
    </div>
  );
};

export default VerifyEmail;
