import axiosInstance from '../utils/axiosInstance';

export const getBlogPosts = async ({pageParam}) => {
  const authToken = localStorage.getItem('_auth');

  // use authToken to make authenticated requests
  const headers = {'Content-Type': 'application/json'};
  if (authToken) {
    headers['Authorization'] = `Bearer ${authToken}`;
  }

  let apiUrl = `/api/blog_post/?page=${pageParam}`;

  const response = await axiosInstance.get(apiUrl, {
    headers: headers
  });

  return {...response.data, prevPage: pageParam};
};

export const getBlogPost = async (blogPostId) => {
  const authToken = localStorage.getItem('_auth');

  // use authToken to make authenticated requests
  const headers = {'Content-Type': 'application/json'};
  if (authToken) {
    headers['Authorization'] = `Bearer ${authToken}`;
  }

  const response = await axiosInstance.get(`/api/blog_post/${blogPostId}/`, {
    headers: headers
  });

  return response.data;
};
