import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import {alpha} from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {useTheme} from '@mui/material/styles';
import {Link as RouterLink} from 'react-router-dom';

import {fDate} from '../../utils/format-time';
// import {fShortenNumber} from '../../utils/format-number';

import Iconify from '../../components/iconify';
import SvgColor from '../../components/svg-color';

// ----------------------------------------------------------------------

export default function PostCard({post, index, postSelect, handleBookmark, handleCopyToClipboard}) {
  const {
    id,
    platform_id,
    // eslint-disable-next-line no-unused-vars
    plataform_author_id,
    content_type,
    title,
    url_link,
    published,
    public_available,
    bookmarked: isBookmark
  } = post;

  const theme = useTheme();

  const startPlayer = () => {
    console.log('startPlayer');
    postSelect(post);
  };

  const contentTypeIconMap = {
    YT:
      theme.palette.mode === 'light'
        ? '/assets/icons/youtube_color.svg'
        : '/assets/icons/youtube_black.svg',
    X:
      theme.palette.mode === 'light'
        ? '/assets/icons/twitter-x-dark.svg'
        : '/assets/icons/twitter-x-light.svg',
    MP: 'favicon-96x96.png'
  };

  const contentTypeMap = {
    YT: 'Youtube',
    X: 'X',
    MP: 'MePilots'
  };

  const contentTypeCoverMap = {
    YT: `https://i.ytimg.com/vi/${platform_id}/hqdefault.jpg`,
    X: url_link ?? '/assets/images/avatars/default_profile_400x400.png',
    MP: url_link ?? '/logo/logo.png'
  };

  const isPostLarge = content_type === 'MP' || index === 0;

  const latestPost = index < 3;

  const renderAvatar = (
    <Avatar
      alt={contentTypeMap[content_type]}
      src={contentTypeIconMap[content_type]}
      sx={{
        zIndex: 9,
        width: 32,
        height: 32,
        position: 'absolute',
        left: (theme) => theme.spacing(3),
        bottom: (theme) => theme.spacing(-2),
        ...((isPostLarge || latestPost) && {
          zIndex: 9,
          top: 24,
          left: 24,
          width: 40,
          height: 40
        })
      }}
    />
  );

  const renderTitle = (
    <Link
      color="inherit"
      variant="subtitle2"
      underline="hover"
      sx={{
        height: 44,
        overflow: 'hidden',
        WebkitLineClamp: 2,
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        ...(isPostLarge && {typography: 'h5', height: 60}),
        ...((isPostLarge || latestPost) && {
          color: 'common.white'
        })
      }}
      onClick={() => startPlayer()}
    >
      {title}
    </Link>
  );

  const renderInfo = (
    <Stack
      direction="row"
      flexWrap="wrap"
      spacing={1.5}
      justifyContent="flex-end"
      sx={{
        mt: 3,
        color: 'text.disabled'
      }}
    >
      {/* {[
        {number: 1, icon: 'eva:message-circle-fill'},
        {number: 1, icon: 'eva:eye-fill'},
        {number: 1, icon: 'eva:share-fill'}
      ].map((info, _index) => (
        <Stack
          key={_index}
          direction="row"
          sx={{
            ...((isPostLarge || latestPost) && {
              opacity: 0.48,
              color: 'common.white'
            })
          }}
        >
          <Iconify width={16} icon={info.icon} sx={{mr: 0.5}} />
          <Typography variant="caption">{fShortenNumber(info.number)}</Typography>
        </Stack>
      ))} */}
      {!public_available && (
        <Stack
          direction="row"
          sx={{
            ...((isPostLarge || latestPost) && {
              opacity: 0.48,
              color: 'common.white'
            })
          }}
        >
          <Link
            component={RouterLink}
            to={`/editor/${platform_id}`}
            variant="contained"
            underline="none"
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: 'inherit', // Inherit color from parent
              textDecoration: 'none' // Remove text decoration
            }}
          >
            <Iconify width={16} icon={'eva:eye-off-fill'} sx={{mr: 0.5}} />
          </Link>
        </Stack>
      )}
      <Stack
        direction="row"
        sx={{
          ...((isPostLarge || latestPost) && {
            opacity: 0.48,
            color: 'common.white'
          })
        }}
      >
        <Tooltip title={isBookmark ? 'Remove bookmark' : 'Add bookmark'}>
          <Link
            variant="contained"
            component="button"
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: 'inherit', // Inherit color from parent
              textDecoration: 'none' // Remove text decoration
            }}
            onClick={() => handleBookmark(id, isBookmark)}
          >
            <Iconify
              width={16}
              icon={isBookmark ? 'eva:bookmark-fill' : 'eva:bookmark-outline'}
              sx={{mr: 0.5}}
            />
          </Link>
        </Tooltip>
        <Tooltip title={'Copy link to clipboard'}>
          <Link
            variant="contained"
            component="button"
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: 'inherit', // Inherit color from parent
              textDecoration: 'none' // Remove text decoration
            }}
            onClick={() => handleCopyToClipboard(id)}
          >
            <Iconify width={16} icon={'eva:share-fill'} sx={{mr: 0.5}} />
          </Link>
        </Tooltip>
      </Stack>
    </Stack>
  );

  const renderCover = (
    <Box
      component="img"
      alt={title}
      // src={cover}
      src={contentTypeCoverMap[content_type]}
      sx={{
        top: 0,
        width: 1,
        height: 1,
        objectFit: 'cover',
        position: 'absolute'
      }}
    />
  );

  const renderDate = (
    <Typography
      variant="caption"
      component="div"
      sx={{
        mb: 2,
        color: 'text.disabled',
        ...((isPostLarge || latestPost) && {
          opacity: 0.48,
          color: 'common.white'
        })
      }}
    >
      {fDate(published)}
    </Typography>
  );

  const renderShape = (
    <SvgColor
      color="paper"
      src="/assets/icons/shape-avatar.svg"
      sx={{
        width: 80,
        height: 36,
        zIndex: 9,
        bottom: -15,
        position: 'absolute',
        color: 'background.paper',
        backgroundColor: (t) =>
          t.palette.mode === 'light' ? t.palette.grey[0] : t.palette.grey[900],
        ...((isPostLarge || latestPost) && {display: 'none'})
      }}
    />
  );

  return (
    <Grid size={{xs: 12, sm: isPostLarge ? 12 : 6, md: isPostLarge ? 6 : 3}}>
      <Card>
        <Box
          onClick={() => startPlayer()}
          sx={{
            position: 'relative',
            pt: 'calc(100% * 3 / 4)',
            ...((isPostLarge || latestPost) && {
              pt: 'calc(100% * 4 / 3)',
              '&:after': {
                top: 0,
                content: "''",
                width: '100%',
                height: '100%',
                position: 'absolute',
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
              }
            }),
            ...(isPostLarge && {
              pt: {
                xs: 'calc(100% * 4 / 3)',
                sm: 'calc(100% * 3 / 4.66)'
              }
            })
          }}
        >
          {renderShape}

          {renderAvatar}

          {renderCover}
        </Box>

        <Box
          sx={{
            p: (theme) => theme.spacing(4, 3, 3, 3),
            ...((isPostLarge || latestPost) && {
              width: 1,
              bottom: 0,
              position: 'absolute'
            })
          }}
        >
          {renderDate}

          {renderTitle}

          {renderInfo}
        </Box>
      </Card>
    </Grid>
  );
}
