import axios from 'axios';

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: `${window.location.protocol}//${window.location.host}`
});

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Return the response if it's successful
    return response;
  },
  (error) => {
    // Check if the error response status is 401
    if (error.response && error.response.status === 401) {
      // Redirect to the /logout URL
      window.location.href = '/logout';
    }
    // Return the error to be handled by the calling code
    return Promise.reject(error);
  }
);

export default axiosInstance;
