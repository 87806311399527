import axiosInstance from '../utils/axiosInstance';

async function UploadImage(file) {
  const authToken = localStorage.getItem('_auth');
  const baseHost = `${window.location.protocol}//${window.location.host}`;

  const formData = new FormData();
  formData.append('image', file);

  try {
    const response = await axiosInstance.post(
      '/api/upload_image/',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${authToken}`
        }
      },
      {withCredentials: false}
    );

    console.log('Upload success:', response.data);
    return new URL(response.data.image, baseHost).toString();
  } catch (error) {
    console.error('Error uploading image:', error);
    // throw error;
  }
}

export default UploadImage;
