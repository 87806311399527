import React, {useState} from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import axiosInstance from '../../utils/axiosInstance';
import {Helmet} from 'react-helmet-async';
import {useNavigate, useParams} from 'react-router-dom';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Iconify from '../../components/iconify';

import Unsplash from '../../components/Unsplash';
import objectToString from '../../utils/objectToString';
import {NotifyAlert} from '../../components/NotifyAlert';

export default function NewPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [sucessMessage, setSuccessMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const {uid, token} = useParams();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setErrors('');
    const formData = new FormData(event.currentTarget);

    const user = {
      uid: uid,
      token: token,
      new_password1: formData.get('password'),
      new_password2: formData.get('password-confirm')
    };

    // Basic form validation
    let tempErrors = {};
    tempErrors.uid = user.uid ? '' : 'UID is required.';
    tempErrors.token = user.token ? '' : 'Token is required.';
    tempErrors.password =
      user.new_password1 === user.new_password2 ? '' : 'Password did not match.';
    tempErrors.password = !user.new_password1 ? 'Password is required.' : tempErrors.password;

    // If errors, return
    if (tempErrors.uid || tempErrors.token || tempErrors.password) {
      setIsLoading(false);
      setErrors(tempErrors);
      setErrorMessage(
        'Field errors:\n' +
          Object.values(tempErrors)
            .filter((error) => error !== '')
            .join('\n')
      );
      return;
    }

    try {
      // Create the POST request
      await axiosInstance.post(`/api/password-reset-confirm/${user.uid}/${user.token}/`, user, {
        headers: {'Content-Type': 'application/json'},
        withCredentials: false
      });

      setSuccessMessage('Password reset successfully.');
      setIsLoading(false);
    } catch (error) {
      console.error('Error reset password:', error);
      setErrorMessage(
        error.response?.data ? objectToString(error.response.data) : 'Failed to reset password'
      );
      setIsLoading(false);
      return;
    }

    // We can't use navigate('/feed') here, authkit needs a new request to backend after login
    // window.location.href = '/feed';
    setIsLoading(false);
  };

  const renderForm = (
    <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 1}}>
      <Stack direction="row" spacing={3} mb={3}>
        <TextField
          required
          fullWidth
          id="password"
          autoComplete="current-password"
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          error={Boolean(errors.password)}
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              )
            }
          }}
        />

        <TextField
          required
          fullWidth
          id="password-confirm"
          autoComplete="current-password"
          name="password-confirm"
          label="Confirm pass"
          type={showPassword ? 'text' : 'password'}
          error={Boolean(errors.password)}
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              )
            }
          }}
        />
      </Stack>

      <Stack direction="row" justifyContent="center">
        <LoadingButton size="small" type="submit" variant="contained" loading={isLoading}>
          Reset Password
        </LoadingButton>
      </Stack>
    </Box>
  );

  return (
    <>
      <Helmet>
        <title> New Password | MePilots </title>
      </Helmet>
      {errorMessage != '' && (
        <NotifyAlert
          isEnabled={true}
          isError={true}
          message={errorMessage}
          closeCallback={() => {
            setErrorMessage('');
          }}
        />
      )}
      {sucessMessage != '' && (
        <NotifyAlert
          isEnabled={true}
          isError={false}
          message={sucessMessage}
          closeCallback={() => {
            setSuccessMessage('');
            navigate('/feed');
          }}
        />
      )}
      <Grid container component="main" sx={{height: '100vh'}}>
        <Unsplash />
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
          sx={{
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        >
          <Box
            sx={{
              // my: 4,
              // mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '100vh'
            }}
          >
            <Stack alignItems="center" justifyContent="center" sx={{height: 1}}>
              <Card
                sx={{
                  p: 5,
                  width: 1,
                  minWidth: 420
                }}
              >
                <Typography variant="h4" mb={2}>
                  Reset Password
                </Typography>

                {renderForm}
              </Card>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
