import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import {useEffect, useState} from 'react';
import {Typography} from '@mui/material';

export const NotifyAlert = ({isEnabled, isError, message, closeCallback = null}) => {
  const [open, setOpen] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const handleClose = (event, reason) => {
    // if (reason === 'clickaway') {
    //   return;
    // }

    if (closeCallback) {
      closeCallback();
    }

    setOpen(false);
  };

  useEffect(() => {
    setOpen(isEnabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
      >
        <Alert
          onClose={handleClose}
          severity={isError ? 'error' : 'success'}
          variant="filled"
          sx={{width: '100%'}}
        >
          <Typography variant="h7" style={{whiteSpace: 'pre-line'}}>
            {message}
          </Typography>
        </Alert>
      </Snackbar>
    </div>
  );
};
